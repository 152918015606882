import React from "react";
import LocateClinicGrid from "../../pages/nav-pages/LocateClinicGrid";
import styled from "styled-components";


const ToggleKL = () => {
  return <Wrapper>

  <LocateClinicGrid noclinics={3} />
  </Wrapper>
};

export default ToggleKL;
const Wrapper = styled.section`
  .title {
    display: none;
  }
`;
